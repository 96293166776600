import React, {useState, useEffect, useMemo} from 'react'
import './ContainerViewer.css';
import { useCookies } from 'react-cookie';
import Swal from 'sweetalert2'

const ContainerCard = React.memo((cci) => {
    const container = cci.container;

    const [cookies, setCookie] = useCookies([container.containerNo]);
    const [disabled, setDisabled] = useState({buy: cookies.hasOwnProperty(container.containerNo + "buy") || false, hire: cookies.hasOwnProperty(container.containerNo + "hire") || false});

    function sendQuoteRequest(type, cID, uID) {
        const data = {
            quoteId: uID,
            containerId: cID,
            quoteType: type,
            token: cci.token
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        fetch('/AddToQuote', requestOptions).then(res=> {
            return res.json();
        }).then(res=> {
            if(res.successful){
                setCookie(container.containerNo + type, true, {maxAge: 86400, sameSite: true});

                let obj = {...disabled, [type]: true};
                setDisabled(obj);
                Swal.fire(
                    'Success.',
                    res.message,
                    'success'
                )
            }
            else {
                Swal.fire(
                    'Error',
                    res.message,
                    'error'
                )
            }
        })
    }

    function requestQuote(type, Id) {
        if(!disabled[type]){
            sendQuoteRequest(type, Id, cci.ID)
        }else {
            Swal.fire(
                'Error',
                "This container is already added to your quote.",
                'error'
            )
        }
    }


    return (
        <div className="ContainerCard center">
            {
                container.photoLink !== null ? <img src={container.photoLink} className={`${container.isSaleable !== 1 && 'unavailable'}`} alt={`${container.gradeName} - ${container.colourName}`} /> : <img src="/assets/img/no-image.webp" alt={`${container.gradeName} - ${container.colourName}`} />
            }
            <div className="d-flex space-between align-center w-90">
                <h3>{container.containerNo}</h3>
                <div className={`availability ${container.isSaleable !== 1 && 'unavailable'}`}>{container.isSaleable === 1 ? "Available" : "Unavailable"}</div>
            </div>
            <div className="col d-flex justify-left w-90">
                <h4 className='no-block-end no-block-start'>{container.description}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>ISO:</span> {container.ISOName}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>Grade:</span> {container.gradeId}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>Colour:</span> {container.colourName}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>Price:</span> {container.salePriceEx}*</h4>
            </div><br />
            <div className='bg-light ptb-1'>
                <div className="d-flex col">
                    <div className="v-spacer"></div>
                    <div className="d-flex w-90 text-center justify-center">
                        <div className={`Button center text-center ${disabled.hire && 'disabled'}`} onClick={()=> requestQuote(1, container.containerId)}>{container.isSaleable !== 1 ? "Request similar containers" : "Add to quote"}</div>
                        <div className="d-flex col justify-center align-center text-center">
                        </div>
                        {
                            /*
                            <div className="d-flex col flex-4 justify-center">
                                <div className={`Button bg-main w-100 center ${disabled.buy && 'disabled'}`} onClick={()=> requestQuote(2, container.containerId)}>Hire</div>
                            </div>
                            */
                        }
                    </div>
                </div>
            </div>
        </div>
    )
});

export default function ContainerViewer(props) {

    const [containers, setContainers] = useState([{}]);
    const [containerSearch, setContainerSearch] = useState();
    
    function filterValues() {
        let outContainers = [];
        if(containerSearch !== undefined) {
            for(let i = 0; i < containers.length; i++) {
                let text = containers[i].containerNo;
                if(text.toUpperCase().indexOf(containerSearch.toUpperCase()) > -1) {
                    outContainers.push(containers[i]);
                }
            }
        }
        else {
            return containers;
        }
        return outContainers;
    }

    useEffect(()=> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"depotGuid":props.GUID, token:props.token})
        };
        fetch('/DepotContainers', requestOptions).then((response) => {return response.json()}).then(response => {
            setContainers(response);
        });
    },[])

    return (
        <div className='ContainerViewer w-100'>
            <div className="w-90 text-center col align-center justify-center">
                <div>
                    <input type="text" placeholder='ABCU3146324' onChange={e=>setContainerSearch(e.target.value)} value={containerSearch}/> <br /><br />
                </div>
                {
                    filterValues().map((container) => {
                        return (
                            <ContainerCard key={container.containerNo} container={container} token={props.token} ID={props.ID}/>
                        )
                    })
                }
            </div>
        </div>
    )
}
