import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import './ContainerViewer.css';
import Swal from 'sweetalert2'

const ContainerCard = React.memo((cci) => {
    const container = cci.container;

    return (
        <div className="ContainerCard center">
            {
                container.photoLink !== null ? <img src={container.photoLink} className={`${container.isSaleable !== 1 && 'unavailable'}`} alt={`${container.gradeName} - ${container.colourName}`} /> : <img src="/assets/img/no-image.webp" alt={`${container.gradeName} - ${container.colourName}`} />
            }
            <div className="d-flex space-between align-center w-90">
                <h3>{container.containerNo}</h3>
                <div className={`availability ${container.isSaleable !== 1 && 'unavailable'}`}>{container.isSaleable === 1 ? "Available" : "Unavailable"}</div>
            </div>
            <div className="col d-flex justify-left w-90">
                <h4 className='no-block-end no-block-start'>{container.description}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>ISO:</span> {container.ISOName}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>Grade:</span> {container.gradeId}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>Colour:</span> {container.colourName}</h4>
                <h4 className='no-block-end no-block-start'><span className='bold'>Price:</span> {container.salePriceEx}*</h4>
            </div><br />
        </div>
    )
});

export default function ContainerViewer(props) {
    const [depotName, setDepotName] = useState("Loading...");
    const urlParams = new URLSearchParams(window.location.search);
    let {locationGUID} = useParams();
    const [password, setPassword] = useState();
    const [containerSearch, setContainerSearch] = useState();

    useEffect(()=> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`/manned/${locationGUID}`, requestOptions).then(res => {
            return res.json()
        }).then(res=> {
            setDepotName(res.name);
        })
    })

    const [containers, setContainers] = useState();

    function getContainers() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"depotGuid":locationGUID, password:password})
        };
        fetch('/NoTokenDepotAccess', requestOptions).then((response) => {return response.json()}).then(response => {
            if(response.successful) {
                setContainers(response.data);
                Swal.fire({
                    title: 'Success',
                    text: "Access Granted",
                    icon: 'success',
                    confirmButtonText: 'Ok'
                })
            }
            else {
                Swal.fire({
                    title: 'Error',
                    text: response.data,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            }
            
        });
    }

    function filterValues() {
        let outContainers = [];
        if(containerSearch !== undefined) {
            for(let i = 0; i < containers.length; i++) {
                let text = containers[i].containerNo;
                if(text.toUpperCase().indexOf(containerSearch.toUpperCase()) > -1) {
                    outContainers.push(containers[i]);
                }
            }
        }
        else {
            return containers;
        }
        return outContainers;
    }

    return (
        <div className='ContainerViewer w-100'>
            <div className="Header w-100 bg-main">
                <img src="/assets/img/abclong.svg" alt="ABC Container Hire and Sales Logo" className='Logo'/>
            </div>
            <div className="Page-Content w-90">
                <div className="w-100 text-center">
                    <h2 className='no-block-end'>Container Viewer</h2>
                    <h3 className='no-block-start color-main'>{depotName}</h3>
                </div>
                <div className="w-90 text-center col align-center justify-center">
                    {
                        containers ?
                        <div>
                            <div>
                                <input type="text" placeholder='ABCU3146324' onChange={e=>setContainerSearch(e.target.value)} value={containerSearch}/> <br /><br />
                            </div>
                            {
                                filterValues().map((container) => {
                                    return (
                                        <ContainerCard key={container.containerNo} container={container}/>
                                    )
                                })
                            }
                        </div>
                        :
                        <div>
                            <input type="text" placeholder='Access Code' onChange={e=>setPassword(e.target.value)} value={password}/> <br /><br />
                            <button className="Button" onClick={getContainers}>Submit</button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
