import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { set, useForm } from 'react-hook-form';
import Swal from 'sweetalert2'
import { useCookies } from 'react-cookie';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import Pdf from '../Grades.pdf';

import './DepotAccess.css';
import ContainerViewer from './ContainerViewer';
import GooglePlacesAC from './GooglePlacesAutocomplete';

export default function DepotAccess() {

    const [cookies, setCookie] = useCookies(["UID", "gateCode"]);

    function setInitialUserID() {
        //if user id is in the cookie UID in the react cookie, return that. Otherwise, return false.
        if(cookies.hasOwnProperty("UID")){
            console.log(cookies.UID);
            return cookies.UID;
        }
        else {
            console.log("nothing")
            return "";
        }
    }

    function setInitialGateCode(){
        if(cookies.hasOwnProperty("gateCode")){
            console.log(cookies.gateCode);
            return cookies.gateCode;
        }
        else {
            console.log("nothing")
            return "Loading...";
        }
    }

    let site_key = "6LcZ9NclAAAAAHiU5UtociIUJu7BHr_v1TKJUGeh";
    const depotLocation = useParams().locationGUID;
    const [token, setToken] = useState("testing");
    const [UserID, setUserID] = useState("");
    const [gateCode, setGateCode] = useState();

    useEffect(()=> {
        setUserID(setInitialUserID());
        setGateCode(setInitialGateCode());
    },[])

    const [OTC, setOTC] = useState("");
    const { register, handleSubmit, formState: { errors }, reset } = useForm();
    const [Address, setAddress] = useState("");
    const [AddressNotify, setAddressNotify] = useState("");
    const [formSection, setFormSection] = useState(0);
    const [manned, setManned] = useState(false);
    const [depotName, setDepotName] = useState("Loading...");

    useEffect(()=> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch(`/manned/${depotLocation}`, requestOptions).then(res => {
            return res.json()
        }).then(res=> {
            setManned(res.manned);
            setDepotName(res.name);
        })
    })

    const process = (proc, data) => {
        if(proc === 0) {
            setFormSection(1);
        }
        if(proc === 1) {
            setUserID(data.ID);
            //set the UID cookie to data.ID for 2 hours.
            setCookie("UID", data.ID, {maxAge: 7200, sameSite: true});
            setFormSection(2);
        }
        if(proc === 2) {
            setGateCode(data.gateCode);
            setCookie("gateCode", data.gateCode, {maxAge: 7200, sameSite: true});
            setFormSection(3);
        }
    }

    function errNotify(name) {
        const type = errors[name]?.type;
    
        let message = 
        type === "required" ? "This field is required:" :
        type === "pattern" ? "Invalid input. You may have forgotten or added an incorrect character:" :
        type === "maxLength" ? "Input is too long. Please double check:" : "";
    
        return message;
    }

    const onSubmit = data => {
        if(Address != "") {
            submitForm({...data,token: token, DepotGuid:depotLocation, OTCType: "DepotAccess", Suburb: Address.label}, '/GetOTC', 1);
        }
        else {
            setAddressNotify("Address may not be empty");
        }
    }

    
    function verifyOTC() {
        submitForm({token: token, Id: UserID, OTC: OTC}, '/VerifyOTC', 2);
    }
    
    function logOut() {
        setCookie("UID", "", {maxAge: 0, sameSite: true});
        setCookie("gateCode", "", {maxAge: 0, sameSite: true});
        setUserID("");
        setGateCode("Loading...");
        setFormSection(0);
    }
    
    const formActive = true; //if true, form will post to the server.

    function submitForm(data,serverEndpoint,proc) {
        if(formActive){
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
    
            fetch(serverEndpoint, requestOptions).then(response => {
                if (!response.ok) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'There was a problem sending your information to the server.',
                        icon: 'error',
                        confirmButtonText: 'Continue',
                        customClass: {
                            confirmButton: "SAColourRed"
                        }
                    })
                    throw new Error('Failed to recieve a response from the server.');
                }
                try {
                    return response.json();
                }
                catch (ex) {
                    Swal.fire({
                        title: 'Error!',
                        text: 'There was a problem sending your information to the server.',
                        icon: 'error',
                        confirmButtonText: 'Continue',
                        customClass: {
                            confirmButton: "SAColourRed"
                        }
                    })
                    throw new Error(ex);
                }
            }).then(data => {
                if (data.successful === true) {
                    process(proc, data)
                    reset();
                    setAddress("");
                }
                else {
                    Swal.fire({
                        title: 'Error!',
                        text: data.message,
                        icon: 'error',
                        confirmButtonText: 'Continue',
                        customClass: {
                            confirmButton: "SAColourRed"
                        }
                    })
                    throw new Error(data.message);
                }
            });
        }
    }

    return (
        <div className='DepotAccess w-100 text-center h-100'>
            <div className="Header w-100 bg-main">
                <img src="/assets/img/abclong.svg" alt="ABC Container Hire and Sales Logo" className='Logo'/>
            </div>
            <div className="Page-Content w-90">

            {UserID === "" && formSection === 0 &&
                <>
                    <h2 className="color-main no-block-end">Depot Access</h2>
                    <h3 className='no-block-start color-dark'>{depotName}</h3>
                    {   !manned ?
                        <p>Please fill in your details on the next page to get the gate code for our un-manned depot. </p> 
                        :
                        <p>Please fill in your details on the next page to get access to our container viewer.</p>
                    }
                    <p>By proceeding with this process, you agree to our <a href="/termsofentry">Terms of Entry</a> and <a href="/privacy">Privacy Policy.</a></p>
                    <br />
                    <button className='Button' onClick={()=>process(0)}>Proceed</button>
                    <br /><br />
                    <p>Don't refresh the page. Refreshing the page will require the sign-in to be completed again.</p>
                    <br />
                    <p className='color-dark no-block-end'>Having Trouble?</p>
                    <p className='no-block-start'>Call us <a href="tel:1300222266">1300 222 266</a></p>
                </>
            }
            {
                formSection === 1 && UserID === "" &&
                <>
                    <h2 className="color-main no-block-end">Depot Access</h2>
                    <h3 className="no-block-start">Your Details</h3>
                    <p>Please enter your details below.</p>
                    <br />
                    <form className='d-flex justify-center' onSubmit={handleSubmit(onSubmit)}>
                        <div className="InputBox center">
                        <label htmlFor='FirstName' className='errNotify'>
                            {errNotify("FirstName")}    
                        </label>
                        <input id="FirstName" type="text" placeholder="First name" {...register("FirstName", {required: true, maxLength: 80})} /> <br /> <br />


                        <label htmlFor='LastName' className='errNotify'>
                            {errNotify("LastName")}    
                        </label>
                        <input id="LastName" type="text" placeholder="Last name" {...register("LastName", {required: true, maxLength: 100})} /> <br /> <br />


                        <label htmlFor='Email' className='errNotify'>
                            {errNotify("Email")}    
                        </label>
                        <input id="Email" type="text" placeholder="Email Address" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} /> <br /> <br />


                        <label htmlFor='PhoneNumber' className='errNotify'>
                            {errNotify("PhoneNumber")}    
                        </label>
                        <input id="PhoneNumber" type="tel" placeholder="Phone number" {...register("PhoneNumber", {required: true, maxLength: 12, pattern: /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/})} />
                        
                        <br /><br />
                        <label htmlFor='Address' className='errNotify'>
                            {AddressNotify}
                        </label>
                        <GooglePlacesAC setValue={setAddress} value={Address} placeholder="Suburb"/>
                        
                        <br /><br />
                        <button type='submit' className='Button w-100'>Confirm</button>
                        </div>
                    </form>
                    <p className="recaptcha">This site is protected by reCAPTCHA and the google<br /><a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.</p>
                    <br />
                    <p className='color-dark no-block-end'>Having Trouble?</p>
                    <p className='no-block-start'>Call us <a href="tel:1300222266">1300 222 266</a></p>
                </>
            }
            {
                formSection === 2 && gateCode === "Loading..." &&
                <>
                    <h2 className="color-main no-block-end">Depot Access</h2>
                    <h3 className="no-block-start">Verification</h3>
                    <p>We have sent a verification number to you via SMS. Type that number into the box below.</p>
                    <br />
                    <form className='d-flex justify-center'>
                        <div className="InputBox center">
                            <label htmlFor="verification">Verification number:<br /></label>
                            <input className='center' id='verification' type="text" placeholder='Verification number' autoFocus onChange={e => setOTC(e.target.value)} value={OTC}  /><br /><br /><br />
                        </div>
                    </form>
                    <button type="submit" className='Button' onClick={()=>verifyOTC()}>Confirm</button><br /><br />{/* change to verifyOTC(); TODO
                    <div className="Clickable" onClick={()=>resendCode()}>Resend verification code</div><br />*/}
                    <br />
                    <p className='color-dark no-block-end'>Having Trouble?</p>
                    <p className='no-block-start'>Call us <a href="tel:1300222266">1300 222 266</a></p>
                </>
            }

            {
                UserID !== "" && gateCode !== "Loading..." &&
                <>
                    {
                        !manned ?
                        <>
                            <h2 className="color-main no-block-end">Depot Access</h2>
                            <h3 className="no-block-start">Your gate code</h3>
                            <p>Your gate code is:</p>
                            <div className="w-100 d-flex justify-center">
                                <div className="gateCode center">{gateCode}</div>
                            </div>
                            <br />
                            <p className='color-main no-block-end'>Having Trouble?</p>
                            <p className='no-block-start'>Call us <a href="tel:1300222266">1300 222 266</a></p>
                            <h3>Containers at this depot:</h3>
                        </>
                        :
                        <>
                            <h2 className="color-main no-block-end">Containers</h2>
                            <h3 className="no-block-start">At this depot</h3>
                        </>
                    }
                    <a href={Pdf} target="_blank">Click here to see container grades specifications</a>
                    <br /><br />
                    <ContainerViewer GUID={depotLocation} token={token} ID={UserID}/>
                    <p>*Prices do not include delivery. Prices are indicative only and subject to change. Please call our office, or request a quote for accurate pricing.</p>
                    <div className="w-100 d-flex justify-center">
                        <div className="Button center" onClick={()=>logOut()}>Log Out</div> <br /><br /><br />
                    </div>
                </>
            }
                            
            </div>
            <GoogleReCaptchaProvider reCaptchaKey={site_key}>
                <GoogleReCaptcha onVerify={token=> {setToken(token)}} />
            </GoogleReCaptchaProvider>
        </div>
    )
}
