//Library imports
import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import { CookiesProvider } from 'react-cookie';

// Main CSS Rules
import './fonts.css'
import './generic.css'
import './specific.css'

import DepotAccess from './DepotAccess/DepotAccess';
import NoAccessContainerViewer from './DepotAccess/NoAccessContainerViewer'
import CodeAccess from './DepotAccess/CodeAccess';
import Terms from './Terms';
import Privacy from './Privacy';
import Failed from './Failed';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <Router>
      <Routes>
        <Route 
          path='/access/:locationGUID' 
          element={<DepotAccess/>}/>
        <Route 
          path='/access-no-login/:locationGUID' 
          element={<NoAccessContainerViewer/>}/>
        <Route 
          path='/privacy' 
          element={<Privacy/>}/>
        <Route 
          path='/termsofentry' 
          element={<Terms/>}/>
        <Route 
          path='/failed' 
          element={<Failed/>}/>
        <Route 
          path='/code-access/:locationGUID/:code' 
          element={<CodeAccess/>}/>
      </Routes>
    </Router>
  </CookiesProvider>
);