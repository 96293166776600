import React from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


export default function GooglePlacesAC(props) {
    //modify this so that the start of the input isn't hidden
    const styles = {
        dropdownIndicator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none',
        }),
        container: (provided) => ({
            ...provided,
            width: '100%',
            overflow: 'visible',
        }),
        control: (provided) => ({
            ...provided,
            borderRadius: 0,
            border: '1px solid #d1d1d1 !important',
            // This line disable the blue border
            boxShadow: '0 !important',
            '&:hover': {
                border: '1px solid #d1d1d1 !important',
            }
        }),
        input: (provided) => ({
            ...provided,
            width: '100%',
            textAlign: 'left',
            cursor: 'text',
            fontSize: 16,
            lineHeight: '24px',
            padding: 0,
            color: '#000000',
        }),
        singleValue: (provided) => ({
            ...provided,
            paddingLeft: 10,
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0 8px',
        }),
        menu: (provided) => ({
            ...provided,
        }),
        option: (provided) => ({
            ...provided,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#d1d1d1',
        }),
    }

    return (
            <GooglePlacesAutocomplete

                apiKey='AIzaSyDax_SaGNJEfl3e6cq1XLgIMvZUB3VUBiQ'
                selectProps={{
                    styles: styles,
                    placeholder: 'Type Address...',
                    value: props.value,
                    onChange: props.setValue,
                }}
                autocompletionRequest={{
                    componentRestrictions: {
                        country: ['au'],
                    }
                }}
                className="GooglePlacesAutocomplete"

            />
    );

}