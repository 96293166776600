import React from 'react'

export default function Terms() {
  return (
    <div className='Terms w-90'>
      <br />
      <b>
        Terms of Entry for ABCCHS Lismore Shipping Container Depot</b>
<br /><br />
Introduction <br />
These Terms of Entry ("Terms") govern your access to and use of the ABC Container Hire And Sales Lismore Shipping Container Depot ("Depot" or "ABCCHS Lismore"), an unmanned facility that provides access to view and inspect shipping containers available for purchase. By scanning the QR code at the entrance and registering through our web application, you agree to be bound by these Terms.
<br /><br />
Registration and Access <br />
2.1. To access the Depot, you must first scan the QR code at the entrance, which will redirect you to our web application. You will be required to register by providing your full name, contact information, and any other necessary information as prompted.
<br />
2.2. Upon successful registration, you will be granted access to the Depot. You are responsible for maintaining the confidentiality of your registration information and ensuring that only authorized individuals access the Depot under your account.
<br />
2.3. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
<br /><br />
Safety and Security <br />
3.1. The Depot is equipped with security cameras for your safety and the protection of the facility. By entering the Depot, you consent to being recorded by these cameras.
<br />
3.2. As the Depot is generally unmanned and may not have machinery operating, you are responsible for your own safety while on the premises. You agree to exercise caution and adhere to any posted safety guidelines or instructions.
<br />
3.3. In the event of an emergency or if you require assistance, please contact the emergency contact number provided in the web application or on signage within the Depot.
<br /><br />
Conduct and Prohibited Activities <br />
4.1. You agree to use the Depot for its intended purpose of viewing and inspecting shipping containers only. Any unauthorized use or activities are strictly prohibited.
<br />
4.2. You are not permitted to:
<br />
a. Operate any machinery, tools, or equipment within the Depot without prior authorization; <br />
b. Tamper with, damage, or alter any shipping containers, equipment, or property within the Depot; <br />
c. Engage in any illegal or criminal activities; <br />
d. Create any safety hazards or impede the safety of others; <br />
e. Smoke, consume alcohol, or use illegal substances within the Depot; <br />
f. Bring any pets or animals into the Depot; <br />
g. Litter, dispose of waste, or create any unsanitary conditions;<br />
h. Disturb other customers or interfere with their use of the Depot. <br />
<br /><br />
Liability <br />
5.1. You agree to indemnify, defend, and hold harmless ABCCHS Lismore and its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of the Depot.
<br />
5.2. You acknowledge that ABCCHS Lismore shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses, resulting from your access to or use of the Depot.
<br /><br />
6. Termination <br />
We reserve the right to terminate your access to the Depot and the web application at any time for any reason, including, without limitation, if we believe that you have violated these Terms.
<br /><br />
7. Changes to Terms <br />
We reserve the right to modify or update these Terms at any time, and any changes will be effective immediately upon posting to our website or the web application. Your continued use of the Depot following the posting of changes constitutes your acceptance of those changes. It is your responsibility to review the Terms periodically for updates.
<br /><br />
8. Severability <br />
If any provision of these Terms is found to be invalid or unenforceable under applicable law, such provision shall be deemed modified to the extent necessary to render it valid and enforceable, and the other provisions of these Terms shall remain in full force and effect.
<br /><br />
9. Waiver <br />
The failure of ABCCHS Lismore to enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
<br /><br />
10. Entire Agreement <br />
These Terms constitute the entire agreement between you and ABCCHS Lismore concerning your access to and use of the Depot, and supersede any prior agreements, understandings, or representations, whether written or oral, with respect to the subject matter hereof.
<br /><br />
11. Contact Information <br />
If you have any questions, concerns, or comments regarding these Terms or your use of the Depot, please contact us at:
<br /> <br />
ABC Container Hire & Sales Pty Ltd <br />
16 Rocla Court, Glenvale, Toowoomba QLD 4350. <br />
Email: admin@abccontainerhiresales.com.au <br />
Phone: (07) 4630 7666
<br /><br />
By scanning the QR code at the entrance and registering through our web application, you acknowledge that you have read, understood, and agree to be bound by these Terms of Entry for the ABCCHS Lismore Shipping Container Depot.
    <br /><br />
    </div>
  )
}
